<template>
	<el-dialog class="dialog" title="发布安卓版本" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :model="formData" label-width="140px" :rules="rules">
				<el-row>
					<el-col :span="12">
						<el-form-item label="版本号：" prop="version">
							<el-input v-model="formData.version"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="版本名称：" prop="name">
							<el-input v-model="formData.name"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="更新类型：" prop="updateType">
							<el-radio-group v-model="formData.updateType">
								<el-radio :label="1">整包更新</el-radio>
								<el-radio :label="2">热更</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="是否强制更新：" prop="forceUpdate">
							<el-radio-group v-model="formData.forceUpdate">
								<el-radio :label="1">是</el-radio>
								<el-radio :label="2">否</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="启用状态：">
							<el-switch v-model="formData.status" active-color="#4A99F6" :active-value="1"
								:inactive-value="0">
							</el-switch>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="下载地址：">
							<el-input v-model="formData.url" disabled></el-input>
							<el-upload class="avatar-uploader" action="" :before-upload="BeforeUpload" :limit="1"
								:on-remove="handleRemove" :http-request="Upload" :file-list="fileList">
								<el-button size="small" type="primary">点击上传</el-button>
								<div slot="tip" class="el-upload__tip">只能上传 apk/wgt 文件</div>
							</el-upload>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="版本描述：">
							<el-input type="textarea" v-model="formData.description"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				title: "",
				loading: false,
				formData: {},
				newFile: new FormData(),
				fileList: [],
				rules: {
					version: [{
						required: true,
						message: "此项必填",
						trigger: 'blur'
					}, {
						validator: (rule, value, callback) => {
							if (!value) {
								return callback(new Error('版本号不能为空'))
							}
							setTimeout(() => {
								if (!Number.isInteger(+value)) {
									callback(new Error('请输入数字'))
								} else {
									callback()
								}
							}, 100)
						},
						trigger: 'blur'
					}],
					name: [{
						required: true,
						message: '此项必填',
						trigger: 'blur'
					}],
					updateType: [{
						required: true,
						message: '此项必填',
						trigger: 'blur'
					}],
					forceUpdate: [{
						required: true,
						message: '此项必填',
						trigger: 'blur'
					}],
				}
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {};
				this.fileList = [];
				this.newFile = new FormData();
				this.$nextTick(() => {
					this.$refs.form.clearValidate();
				})
			},
			//上传前校验
			BeforeUpload(file) {
				if (this.fileList.length > 0) {
					this.$message.error('只能上传一份');
					return false;
				}
				const isLt2M = file.size / 1024 / 1024 < 2;
				var filtype = file.name.substring(file.name.lastIndexOf(".") + 1);
				const extension = filtype === "apk" || filtype === "wgt";
				if (!extension) {
					this.$message.error('上传文件只能是 apk/wgt 格式!');
				} else {
					if (file) {
						if(this.newFile.has("file")){
							this.newFile.delete("file");
						}
						this.newFile.append('file', file);
						return true;
					} else {
						return false;
					}
				}
				return false;
			},
			handleRemove(file, fileList) {
				this.formData.url = "";
				this.fileList = fileList;
			},
			//手动上传
			Upload() {
				this.loading = true;
				this.$uploadWithName(this.newFile).then((res) => {
					if (res.code == 1000) {
						this.formData.url = res.data
						this.loading = false;
					} else {
						this.loading = false;
						this.$message.error(res.msg);
					}
				}).catch(error => {
					this.$message.error(error);
				});
			},
			//提交表单
			submit() {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.$postData("/base-api/app/version/android/save", this.formData).then((res) => {
							if (res.code == 1000) {
								this.loading = false;
								this.$message.success(res.msg);
								this.$parent.list();
								this.close();
							} else {
								this.loading = false;
								this.$message.error(res.msg);
							}
						});
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.cascader {
			display: block;
		}

		::v-deep.el-image {
			width: 80px;
			height: 80px;
			margin-right: 20px;
		}

		.images {
			margin: 10px 25px;
		}
	}
</style>
