<template>
	<div class="flex" style="flex-direction: column;height: 100%;">
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据列表</span>
				</div>
				<div class="title-right">
					<el-button type="primary" size="medium" @click="insert()" style="margin-right: 8px;">
						发布安卓版本
					</el-button>
					<el-select v-model="searchParm.size" size="medium" :popper-append-to-body="false" placeholder="显示条数"
						@change="list()">
						<el-option label="5" :value="5"></el-option>
						<el-option label="10" :value="10"></el-option>
						<el-option label="20" :value="20"></el-option>
						<el-option label="50" :value="50"></el-option>
						<el-option label="100" :value="100"></el-option>
					</el-select>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table ref="usertable" :data="tableData.records" border :height="tableHeight"
							:header-cell-style="{'background-color':'#f9fafc'}" :cell-style="{'padding':0}"
							style="margin-bottom: 20px;">
							<el-table-column prop="create_time" align="center" label="发布时间" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="version" align="center" label="版本号" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="name" align="center" label="版本名称" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="updateType" align="center" label="更新类型" show-overflow-tooltip>
								<template slot-scope="scope">
									<div v-if="scope.row.updateType==1">整包更新</div>
									<div v-if="scope.row.updateType==2">热更</div>
								</template>
							</el-table-column>
							<el-table-column prop="forceUpdate" align="center" label="是否强制" show-overflow-tooltip>
								<template slot-scope="scope">
									<div v-if="scope.row.forceUpdate==1">是</div>
									<div v-if="scope.row.forceUpdate==2">否</div>
								</template>
							</el-table-column>
							<el-table-column prop="url" align="center" label="地址" width="460">
							</el-table-column>
							<el-table-column prop="description" align="center" label="版本描述" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="status" align="center" label="启用状态">
								<template slot-scope="scope">
									<el-switch v-model="scope.row.status" active-color="#4A99F6"
										@change="changeSwitch($event,scope.row)" :active-value="1" :inactive-value="0">
									</el-switch>
								</template>
							</el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="160">
								<template slot-scope="scope">
									<el-button type="text" @click="remove(scope.row)">
										删除记录
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination style="float: right;" @current-change="handleCurrentChange"
						:current-page.sync="searchParm.current" :page-size="searchParm.size"
						layout="total, prev, pager, next,jumper" :total="tableData.total">
					</el-pagination>
				</div>
			</div>
		</div>

		<edit :isShow.sync="showEditDialog" :dialogObj="editObj"></edit>
	</div>
</template>
<script>
	import smCard from "@c/smCard.vue"
	import edit from "./edit.vue"
	export default {
		components: {
			smCard,
			edit,
		},
		data() {
			return {
				tableHeight: 0,
				tableData: {
					records: [],
				},
				searchParm: {
					size: 20,
					current: 1
				},
				// batchActionType: '',
				showEditDialog: false,
				showLookDialog: false,
				editObj: {},
				lookObj: {},
				roles: [],
			}
		},
		methods: {
			//自适应表格高度
			calHeight() {
				this.$nextTick(() => {
					const rect = this.$refs.tableContainer.getBoundingClientRect()
					this.tableHeight = rect.height
				})
			},
			//清空搜索
			clearSearch() {
				this.searchParm = {
					size: 20,
					current: 1
				}
			},
			//展示数据
			list() {
				this.$get("/base-api/app/version/android/list", this.searchParm).then((res) => {
					if (res.code == 1000) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//打开新增弹窗
			insert() {
				this.editObj = {};
				this.editObj["type"] = 2;
				this.showEditDialog = true;
			},
			//分页选择页面回调
			handleCurrentChange(val) {
				this.searchParm.current = val;
				this.list();
			},
			//删除
			remove(row) {
				this.$confirm("是否确认删除？", "删除记录", {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post("/base-api/app/version/android/delete", {
						id: row.id
					}).then((res) => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				}).catch(() => {});
			},
			//点击账户启用状态
			changeSwitch(val, row) {
				console.log(row);
				this.$postData("/base-api/app/version/android/status", {
					id: row.id,
					status: val
				}).then((res) => {
					if (res.code == 1000) {
						this.$message.success(res.msg);
						//刷新表格
						this.list();
					} else {
						this.$message.error(res.msg);
					}
				});
			},
		},
		mounted() {
			this.list();
			this.calHeight();
			window.addEventListener('resize', this.calHeight)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.calHeight)
		},
	};
</script>
<style lang='scss' scoped>
	@import "@style/userCenter.scss";

	.tableModule .tableContainer {
		::v-deep .table .cell {
			text-overflow: clip;
			white-space: normal;
			overflow: auto;
			word-break: break-word;
		}
	}
</style>
